// Derived knowledge types

import { map, mapValues } from "lodash";
import {
  BaseDerivedPropertyType,
  CountPropertyType,
  DerivedPropertyTerm,
  DerivedPropertyType,
  PropertyOpType,
  TimeUnit,
} from "./derived";
import { PropertyKnowledgeRef } from "./knowledge";
import { FetchNRequest, GROUP_BY_ALL, Neighborhood, PathNode } from "./query";

interface UnaryDerivedPropertyType extends BaseDerivedPropertyType {
  term: {
    op: "property";
    property_type: PropertyKnowledgeRef;
  };
}

interface SumPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Sum;
}

interface AvgPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Avg;
}

interface MedianPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Median;
}

interface PercentilePropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Percentile;
  percentage: number;
  approx: boolean;
}

interface MaxPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Max;
}

interface MinPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Min;
}

interface NtilePropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.Ntile;
  n: number;
}

interface DateTruncPropertyTypeV2 extends UnaryDerivedPropertyType {
  op: PropertyOpType.DateTrunc;
  bucket_size: TimeUnit;
}

interface AddPropertyTypeV2 extends BaseDerivedPropertyType {
  op: PropertyOpType.Add;
  terms: DerivedPropertyTermV2[];
}

interface SubtractPropertyTypeV2 extends BaseDerivedPropertyType {
  op: PropertyOpType.Subtract;
  terms: DerivedPropertyTermV2[];
}

interface DividePropertyTypeV2 extends BaseDerivedPropertyType {
  op: PropertyOpType.Divide;
  divisor: DerivedPropertyTermV2;
  dividend: DerivedPropertyTermV2;
}

interface DateDiffPropertyTypeV2 extends BaseDerivedPropertyType {
  op: PropertyOpType.DateDiff;
  unit: TimeUnit;
  start: DerivedPropertyTermV2;
  end: DerivedPropertyTermV2;
}

interface MultiplyPropertyTypeV2 extends BaseDerivedPropertyType {
  op: PropertyOpType.Multiply;
  factors: DerivedPropertyTermV2[];
}

type DerivedPropertyTypeV2 =
  | SumPropertyTypeV2
  | AvgPropertyTypeV2
  | MedianPropertyTypeV2
  | PercentilePropertyTypeV2
  | MaxPropertyTypeV2
  | MinPropertyTypeV2
  | AddPropertyTypeV2
  | SubtractPropertyTypeV2
  | DividePropertyTypeV2
  | DateDiffPropertyTypeV2
  | DateTruncPropertyTypeV2
  | MultiplyPropertyTypeV2
  | CountPropertyType
  | NtilePropertyTypeV2;

type DerivedPropertyTermV2 = DerivedPropertyTypeV2 | PropertyKnowledgeRef;

function convertPropertyTerm(term: DerivedPropertyTerm): DerivedPropertyTermV2 {
  if (typeof term === "string") {
    return term;
  }
  return convertPropertyType(term);
}

function convertPropertyType(type: DerivedPropertyType): DerivedPropertyTypeV2 {
  switch (type.op) {
    case PropertyOpType.Count:
      return type;
    case PropertyOpType.Max:
    case PropertyOpType.Min:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Sum:
      return { op: type.op, term: { op: "property", property_type: type.property_type } };
    case PropertyOpType.Percentile:
      return {
        op: type.op,
        term: { op: "property", property_type: type.property_type },
        percentage: type.percentage,
        approx: type.approx,
      };
    case PropertyOpType.Ntile:
      return {
        op: type.op,
        term: { op: "property", property_type: type.property_type },
        n: type.n,
      };
    case PropertyOpType.DateTrunc:
      return {
        op: type.op,
        term: { op: "property", property_type: type.property_type },
        bucket_size: type.bucket_size,
      };
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
      return { op: type.op, terms: map(type.terms, convertPropertyTerm) };
    case PropertyOpType.Multiply:
      return { op: type.op, factors: map(type.factors, convertPropertyTerm) };
    case PropertyOpType.Divide:
      return {
        op: type.op,
        dividend: convertPropertyTerm(type.dividend),
        divisor: convertPropertyTerm(type.divisor),
      };
    case PropertyOpType.DateDiff:
      return {
        op: type.op,
        unit: type.unit,
        start: convertPropertyTerm(type.start),
        end: convertPropertyTerm(type.end),
      };
  }
}

/**
 * Convert from old to new query engine request format.
 */
export function convertRequestFormat(request: FetchNRequest): FetchNRequestV2 {
  const oldNeighbors = request.neighbors;
  let neighbors: Record<string, NeighborhoodV2> | undefined = undefined;
  if (oldNeighbors !== undefined) {
    neighbors = mapValues(oldNeighbors, convertNeighborhood);
  }

  const oldProperties = request.properties;
  let properties: Record<string, DerivedPropertyTermV2> | undefined = undefined;
  if (oldProperties !== undefined) {
    properties = mapValues(oldProperties, convertPropertyTerm);
  }

  const oldGroupBy = request.group_by;
  let group_by: DerivedPropertyTermV2[] | typeof GROUP_BY_ALL | undefined = undefined;
  if (oldGroupBy === GROUP_BY_ALL) {
    group_by = oldGroupBy;
  } else if (oldGroupBy !== undefined) {
    group_by = map(oldGroupBy, convertPropertyTerm);
  }

  return { ...request, neighbors, properties, group_by };
}

function convertNeighborhood(neighborhood: Neighborhood): NeighborhoodV2 {
  return map(neighborhood, (el) => (typeof el === "string" ? el : convertPathNode(el)));
}

function convertPathNode(pathNode: PathNode): PathNodeV2 {
  if (!pathNode.properties) {
    return { ...pathNode, properties: undefined };
  }
  const properties = mapValues(pathNode.properties, convertPropertyTerm);
  return { ...pathNode, properties };
}

type NeighborhoodV2 = Array<string | PathNodeV2>;

interface PathNodeV2 extends Omit<PathNode, "properties"> {
  properties?: Record<string, DerivedPropertyTermV2>;
}
interface FetchNRequestV2 extends Omit<FetchNRequest, "neighbors" | "properties" | "group_by"> {
  neighbors?: Record<string, NeighborhoodV2>;
  properties?: Record<string, DerivedPropertyTermV2>;
  group_by?: DerivedPropertyTermV2[] | typeof GROUP_BY_ALL;
}

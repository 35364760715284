<template>
  <div class="container">
    <ResizeWrapper
      vertical="top"
      @update:height="handleHeightUpdate"
      :minimum-length="HEADER_HEIGHT"
      :maximum-length="maxHeight"
      identifier="SourceBrowser.resources"
      v-if="sourceBrowserExpanded"
    >
      <span class="title-line">
        <div class="title">Source Browser</div>
        <IconButton
          :name="sourceBrowserExpanded ? 'hide-panel' : 'reveal-panel'"
          data-test="expand-sources"
          @click="toggleExpanded"
        />
      </span>
      <div
        class="source-browser-container"
        :class="{ '-extended': sourceBrowserExpanded, '-retracted': !sourceBrowserExpanded }"
        :style="{
          height: height > 0 ? height + 'px' : '100%',
        }"
      >
        <div class="inner-source-browser">
          <div class="header">
            <div class="search">
              <Textbox v-model="sourceBrowserStore.search" type="search" :disabled="!isConnected" />
            </div>
            <div class="source-browser-header" @click="toggleServerDetails">
              <div class="lake-header">
                <Icon
                  name="md:ac_unit"
                  v-if="environment.require('DATA_PROVIDER') === 'snowflake'"
                />
                <Icon name="databricks" v-else />
                <div class="title">
                  <div>{{ workspaceName }}</div>
                </div>
                <IconButton name="refresh-line" @click.stop="refreshTables()" v-if="isConnected" />
              </div>
              <Tooltip placement="bottom" :delay="{ show: 100, hide: 100 }">
                <template #popper>
                  <div class="tooltip-content">{{ warehouseStatus }}</div>
                </template>
                <span v-if="warehouseStatusColor" class="status">
                  <StatusPill :color="warehouseStatusColor" :label="warehouseStatusString" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div v-if="showServerDetails" class="edit-server">
            Switch between your Databricks workspaces and SQL warehouses. To add workspaces, go to
            <a class="connections-link" :href="CONNECTIONS_URL">connection settings.</a>
            <div
              v-if="
                databricksStore.currentWorkspace &&
                databricksStore.workspaces?.length &&
                allowWorkspaceSwitching
              "
              class="dropdown"
            >
              <Select
                label="Workspace"
                :options="databricksStore.workspaces"
                option-label="nickname"
                :option-value="(w) => w.host"
                :model-value="databricksStore.currentWorkspaceHost"
                @update:model-value="selectWorkspace"
              />
            </div>

            <div
              v-if="databricksStore.currentWorkspace && databricksStore.warehouses.length"
              class="dropdown"
            >
              <Select
                label="Warehouse"
                v-model="databricksStore.currentWorkspace.sql_http_path"
                :options="warehouses"
                option-label="name"
                :option-value="getSqlPath"
                @update:model-value="updateWarehouse"
              />
            </div>
          </div>

          <div class="inner-body" v-if="isConnected">
            <div class="inner-source">
              <SystemResources :system-id="SYSTEM_ID" />
            </div>
          </div>
          <div class="inner-actions" v-if="selectionCount > 0">
            <div class="count">{{ pluralize("data source", selectionCount, true) }} selected</div>
            <TextButton label="Load" data-test="load" @click="commitTables" />
          </div>
        </div>
      </div>
    </ResizeWrapper>
    <div v-else>
      <span class="title-line">
        <div class="title">Source Browser</div>
        <IconButton
          :name="sourceBrowserExpanded ? 'hide-panel' : 'reveal-panel'"
          @click="toggleExpanded"
        />
      </span>
    </div>
  </div>
  <Dialog
    v-if="deletedWarehouseWarning"
    title="DELETED WAREHOUSE"
    success-label="Go to settings"
    @succeeded="useNavigation().goToUrl(CONNECTIONS_URL)"
    @cancelled="deletedWarehouseWarning = false"
  >
    {{ currentWarehouse?.name }} has been deleted. You must select a new warehouse to continue.
  </Dialog>
</template>
<style lang="scss">
.claritype-dropdown .vs__dropdown-toggle {
  background: var(--Black);
}
</style>

<style lang="scss" scoped>
.inner-source {
  overflow-y: auto;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
}

.title-line {
  border-top: 2px solid $gray2;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 $wide-margin;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;

    color: var(--Light-Gray);
    font-size: 14px;
    font-weight: $weight-bold;
    margin: $normal-margin 0;
  }
}

.source-browser-container {
  position: relative;

  &.-extended {
    flex-grow: 1;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  &.-retracted {
    flex-grow: 0;
    transition-duration: 0.5s;
  }
}
.inner-source-browser {
  @include full-size;
  display: flex;
  flex-direction: column;

  > .header {
    .search {
      padding: 0 $wide-margin $thin-margin $normal-margin;
    }
  }

  .edit-server {
    color: var(--Light-Gray);
    padding: 8px 31px 20px 40px;

    .dropdown {
      padding: $normal-margin 0 0;
    }
  }
}

.inner-body {
  flex: 0 1 100%;
  overflow-y: auto;
  max-height: fit-content;
  padding-left: $wider-margin;
}
.source-browser-header {
  &:hover {
    cursor: pointer;
    background-color: var(--Black);
  }
  padding: $normal-margin $wide-margin $normal-margin $ludicrous-margin;
  .status {
    vertical-align: text-top;
    cursor: pointer;
    padding-left: 30px;
    display: flex;
  }
}
.lake-header {
  display: flex;
  align-items: center;

  > .title {
    margin-left: $normal-margin;
    flex-direction: row;
    gap: $normal-margin;
    justify-content: flex-start;
    align-content: center;
    display: flex;
    flex: 1 0 0;
    color: var(--Light-Gray);
    font-size: 14px;
    font-weight: $weight-bold;
  }
}

.inner-source {
  > .header {
    display: flex;
    align-items: center;
    margin: 0 $normal-margin 0 $wider-margin;

    > .title {
      margin-left: $normal-margin;
      flex-direction: row;
      gap: $normal-margin;
      justify-content: flex-start;
      align-content: center;
      display: flex;
      flex: 1 0 0;
      color: var(--Light-Gray);
      font-size: 14px;
      font-weight: $weight-bold;

      .status {
        font-size: 12px;
        vertical-align: text-top;
        cursor: pointer;
      }
    }
  }
}

.inner-actions {
  margin: $wide-margin;
  margin-left: $ludicrous-margin;
  display: flex;
  align-items: center;

  .count {
    flex: 0 1 100%;
    color: var(--Light-Gray);
  }
}

.connections-link {
  color: $orange;
  text-decoration: none;
}
</style>
<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import StatusPill from "@/common/components/StatusPill.vue";
import SystemResources from "@/common/components/SystemResources.vue";
import { SourceSelection, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { Tooltip } from "floating-vue";
import pluralize from "pluralize";
import { computed, onMounted, ref, watch } from "vue";
import { useDatabricksStore, Warehouse } from "../stores/databricksStore";
import { storeToRefs } from "pinia";
import IconButton from "./IconButtonV2.vue";
import { useTimer } from "@/common/composables/useTimer";
import { environment } from "@/common/environments/environmentLoader";
import { capitalize } from "lodash";
import ResizeWrapper from "@/editor/components/ResizeWrapper.vue";
import { AsyncStatus } from "../lib/async";
import Select from "@/common/components/Select.vue";
import Dialog from "@/common/components/Dialog.vue";
import { useUserModuleStore } from "@/common/stores/userModuleStore";
import { useRoute } from "vue-router";
import { useNavigation } from "@/common/composables/useNavigation";

const height = ref(-1);
const HEADER_HEIGHT = 40;
const showServerDetails = ref(false);
const CONNECTIONS_URL = "/home/connections";
const deletedWarehouseWarning = ref(false);
const props = withDefaults(
  defineProps<{
    startExpanded?: boolean;
    parentHeight?: number;
    allowWorkspaceSwitching?: boolean;
  }>(),
  {
    startExpanded: true,
    allowWorkspaceSwitching: false,
  }
);

const emit = defineEmits<{
  (e: "select-tables", selections: SourceSelection[]): void;
  (e: "expanded", expanded: boolean): void;
}>();

const sourceBrowserExpanded = ref(props.startExpanded);
const maxHeight = ref(props.parentHeight);
const route = useRoute();

watch(
  () => props.parentHeight,
  (newVal) => {
    maxHeight.value = newVal;
    height.value = (newVal || 0) * 0.85; //do not start completely expanded so there's room to make the drawer bigger
  },
  { immediate: true }
);

defineExpose({ sourceBrowserExpanded });

function handleHeightUpdate(newHeight: number) {
  height.value = newHeight - HEADER_HEIGHT;
}

function toggleExpanded() {
  sourceBrowserExpanded.value = !sourceBrowserExpanded.value;
  emit("expanded", sourceBrowserExpanded.value);
}

function toggleServerDetails() {
  showServerDetails.value = !showServerDetails.value;
}

const sourceBrowserStore = useSourceBrowserStore();
const databricksStore = useDatabricksStore();

const { warehouses } = storeToRefs(databricksStore);

const SYSTEM_ID = "dbx";

function commitTables() {
  emit("select-tables", sourceBrowserStore.selection);
}

const selectionCount = computed(() => sourceBrowserStore.selection.length);
const isConnected = computed(() => databricksStore.connected);

const warehouseStatusColor = computed(() => {
  switch (currentWarehouse?.value?.state) {
    case "RUNNING":
      return "green";
    case "STARTING":
      return "yellow";
    case "STOPPED":
    case "STOPPING":
      return "red";
    case "DELETED":
    case "DELETING":
      return "black";
  }
  return undefined;
});

const warehouseStatusString = computed(() => {
  const status = currentWarehouse?.value?.state;
  return capitalize(status);
});

const { currentWarehouse, currentWorkspace } = storeToRefs(databricksStore);

const timer = useTimer(environment.requireNumber("LOADING_WAREHOUSE_CHECK_WAIT_MS"));

const warehouseStatus = computed(() => {
  const warehouse = currentWarehouse?.value;
  if (!warehouse) {
    return undefined;
  }
  return `Warehouse "${warehouse.name}" is ${warehouse.state}`;
});

const workspaceName = computed(() => {
  return currentWorkspace?.value?.nickname || "Databricks Lake";
});

watch(
  () => workspaceName,
  () => {
    refreshTables;
  }
);

watch(
  () => currentWarehouse?.value?.state,
  (newWarehouseState, oldWarehouseState) => {
    if (
      newWarehouseState === "DELETED" &&
      newWarehouseState !== oldWarehouseState &&
      route.path !== "/connections"
    ) {
      deletedWarehouseWarning.value = true;
    }
  }
);

function refreshTables() {
  const system = () => sourceBrowserStore.system(SYSTEM_ID);
  system().catalogs.status = AsyncStatus.NotStarted;
  sourceBrowserStore.loadSystemTables(SYSTEM_ID, true);
}
onMounted(async () => {
  await databricksStore.checkConnected();
  databricksStore.getCurrentWarehouse();
  databricksStore.loadWorkspace();
  timer.start(async () => await databricksStore.getCurrentWarehouse());
  databricksStore.listWorkspaces();
  databricksStore.listWarehouses();
});

async function selectWorkspace(workspaceName: string) {
  await databricksStore.selectWorkspace(workspaceName);
  await useUserModuleStore().boot();
}

function getSqlPath(warehouse: Warehouse) {
  return warehouse.sql_path;
}

async function updateWarehouse() {
  await databricksStore.save();
  await useUserModuleStore().boot();
}
</script>

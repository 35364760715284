<template>
  <button
    class="inline-flex cursor-pointer items-center justify-center gap-5 text-center"
    :disabled="disabled"
    :class="[
      `text-${textColor}`,
      {
        'active:shadow-none hover:bg-gray-200 hover:shadow-light active:bg-gray-100 dark:hover:bg-gray-900 dark:active:bg-black':
          !(disabled || darkHover),
        'border-none text-lg uppercase': !icon && !pill,
        'border border-solid text-base normal-case': icon,
        'hover:shadow-none hover:text-gray-700 dark:hover:text-white dark:active:border-black':
          icon && !disabled,
        'border-gray-200 dark:border-gray-900': !selected && icon,
        'bg-transparent': !selected && !pill,
        'shadow-none border-orange bg-orange hover:bg-orange hover:text-white': selected,
        'active:bg-gray hover:bg-black': darkHover && !disabled,
        'hover:bg-yellow-700 active:bg-yellow-900': mode === 'warning' && !disabled,
        'hover:bg-red-700 active:bg-red-900': mode == 'error' && !disabled,
        'gap-10': menu,
        'cursor-not-allowed opacity-100': disabled,
        'rounded px-[6px] py-[4px]': !pill,
        'rounded-[20px] p-5': pill,
        'bg-gray-200 dark:bg-gray-900': pill && !disabled,
      },
    ]"
  >
    <img
      class="pointer-events-none block"
      :class="{
        'svg-to-orange': !(disabled || selected),
        'svg-to-dark-gray': darkMode && disabled,
        'svg-to-light-gray': !darkMode && disabled,
        'svg-to-black active:svg-to-orange': darkMode && selected,
        'svg-to-dark-gray active:svg-to-light-gray': !darkMode && selected,
      }"
      v-if="icon"
      :src="iconSrc"
      :width="sizePx"
      :height="sizePx"
    />
    {{ label }}
  </button>
</template>

<script setup lang="ts">
import { toRefs, computed, inject, Ref } from "vue";
import { parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";

const props = withDefaults(
  defineProps<{
    label: string;
    disabled?: boolean;
    secondary?: boolean;
    icon?: string;
    selected?: boolean;
    darkHover?: boolean;
    mode?: "warning" | "error";
    menu?: boolean;
    textColor?: string;
    pill?: boolean;
  }>(),
  {
    menu: false,
    pill: false,
  }
);
const { icon, menu } = toRefs(props);

const darkMode = inject(DarkMode) as Ref<boolean>;

const iconSrc = computed(() => (icon.value ? parseIconName(icon.value) : undefined));
const sizePx = computed(() => (menu.value ? 20 : 16));
const textColor = computed(function () {
  if (props.disabled) {
    return darkMode.value ? "gray-700" : "gray-200";
  }
  if (props.textColor) {
    return props.textColor;
  }
  if (props.selected) {
    return darkMode.value ? "gray-200" : "white";
  }
  if (props.secondary) {
    return "gray-500";
  }
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  if (props.mode === "error") {
    return "white";
  }
  if (props.mode === "warning") {
    return "yellow-500";
  }
  return "orange";
});
</script>

<template>
  <div class="header">
    <Icon :name="iconName" :color="{ dark: 'white', light: 'black' }" />
    <div class="title">{{ title }}</div>
  </div>

  <div class="loading" v-if="queryResults.status === AsyncStatus.InProgress">
    <Spinner />
  </div>

  <div v-if="queryResults.status === AsyncStatus.Failed">{{ queryResults.message }}</div>
  <div v-if="queryResults.status === AsyncStatus.Succeeded">
    <div class="properties">
      <ValueGroup
        v-for="(properties, type) of propertyValuesByType"
        :values="properties"
        :property-type="type as PropertyKnowledgeRef"
        :key="type"
      />
    </div>

    <NeighborhoodSummary
      v-for="(query, key) of neighborhoods"
      :key="key"
      :query="query"
      @navigate="(address) => emit('navigate', address)"
      @pivot="() => pivotTo(query)"
    />
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  margin-bottom: $normal-margin;

  .title {
    flex: 0 1 100%;
    margin: 0 $thin-margin;
    font-size: 20px;
    font-weight: bold;
  }
}

.loading {
  display: flex;
  padding: $wide-margin;
  justify-content: center;
}
</style>

<script lang="ts" setup>
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { AsyncStatus, asyncHasValue } from "@/common/lib/async";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { FetchNRequest, Neighborhood, PathNode, PropertyFilter } from "@/common/lib/query";
import useQuery from "@/reader/composables/useQuery";
import {
  ConceptAddress,
  buildConceptQuery,
  buildNeighborhoodQueries,
  readerConceptTitle,
} from "@/reader/lib/concept";
import { groupBy, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import NeighborhoodSummary from "./NeighborhoodSummary.vue";
import ValueGroup from "./ValueGroup.vue";
import { useAppStore } from "@/reader/stores/app";
import { formatValue } from "@/common/lib/format";

const props = defineProps<{ address: ConceptAddress }>();
const { address } = toRefs(props);

const emit = defineEmits<{
  (e: "navigate", address: ConceptAddress): void;
  (
    e: "pivot",
    conceptType: ConceptKnowledgeRef,
    filters: PropertyFilter[],
    neighborhood: Neighborhood
  ): void;
}>();

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcept } = useQuery(
  appStore.module!,
  () => buildConceptQuery(address.value),
  () => appStore.map
);

const neighborhoods = computed(() => buildNeighborhoodQueries(address.value));

const title = computed(function () {
  const conceptLabel = typeLabel(address.value.conceptType);
  if (asyncHasValue(queryResults.value)) {
    return readerConceptTitle(rootConcept()) ?? conceptLabel;
  } else {
    return `Loading ${conceptLabel}...`;
  }
});

const propertyValuesByType = computed(function () {
  const grouped = groupBy(rootConcept().properties || [], "type");
  return mapValues(grouped, (props, type) =>
    props.map((prop) => formatValue(type as PropertyKnowledgeRef, prop.value!))
  );
});

const iconName = computed(() => getConceptIconName(address.value.conceptType));

function pivotTo(query: FetchNRequest) {
  const neighborhood = query.neighbors!["root"];
  emit("pivot", query.concept_type, query.filters as PropertyFilter[], [
    neighborhood[0],
    (neighborhood[1] as PathNode).concept_type,
  ]);
}
</script>

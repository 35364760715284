import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import {
  GraphConcept,
  invertLinkDescriptor,
  LinkDescriptor,
  linkPartner,
  stringifyProperty,
} from "@/common/lib/graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef, ROLE_LINK_TYPE } from "@/common/lib/knowledge";
import { getMapClausesWhere, MapSectionKey } from "@/common/lib/map";
import { FetchNRequest, FilterType } from "@/common/lib/query";
import { GraphValue, isValue } from "@/common/lib/value";
import { every, fromPairs, isString } from "lodash";
import { useAppStore } from "../stores/app";

type ConceptKeySet = Record<PropertyKnowledgeRef, GraphValue>;

export interface ConceptAddress {
  conceptType: ConceptKnowledgeRef;
  keys: ConceptKeySet;
}

interface ConceptDisplayTitleInterpolation {
  property_type: PropertyKnowledgeRef; // More parameters to come
}

type ConceptDisplayTitle = Array<string | ConceptDisplayTitleInterpolation>;

export interface ConceptDisplay {
  titles?: ConceptDisplayTitle[];
}

export function buildConceptQuery(address: ConceptAddress): FetchNRequest {
  const { getConceptsOfType } = useGraph(() => useAppStore().metagraph);
  const concept = getConceptsOfType(address.conceptType)[0];
  return {
    concept_type: address.conceptType,
    size: 1,
    filters: Object.entries(address.keys).map(([pt, value]) => ({
      type: FilterType.Equality,
      property_type: pt as PropertyKnowledgeRef,
      value,
    })),
    properties: fromPairs((concept.properties || []).map((p) => [p.type, p.type])),
  };
}

export function buildNeighborhoodQueries(address: ConceptAddress) {
  const { getLinksWith, getConcept, getConceptsOfType } = useGraph(() => useAppStore().metagraph);
  const concept = getConceptsOfType(address.conceptType)[0];
  const queries: Record<string, FetchNRequest> = {};
  for (const link of getLinksWith(concept.id)) {
    const partner = linkPartner(link, concept.id);
    if (partner == null) continue; // Don't handle self-links
    let linkDescriptor = LinkDescriptor.RelatedTo;
    let neighborConcept;
    if (link.from === concept.id) {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.AsA;
      neighborConcept = getConcept(link.to);
    } else {
      if (link.type === ROLE_LINK_TYPE) linkDescriptor = LinkDescriptor.RoleOf;
      neighborConcept = getConcept(link.from);
    }
    const props = (neighborConcept.properties || []).map((p) => p.type);
    // If we can't construct a title or a link, no point in including this
    if (props.length == 0) continue;
    queries[`${linkDescriptor}-${neighborConcept.type}`] = {
      concept_type: neighborConcept.type,
      size: 10,
      properties: fromPairs(Array.from(props).map((prop) => [prop, prop])),
      neighbors: {
        root: [
          invertLinkDescriptor(linkDescriptor),
          {
            concept_type: address.conceptType,
            tag: "root",
          },
        ],
      },
      filters: Object.entries(address.keys).map(([pt, value]) => ({
        type: FilterType.Equality,
        property_type: pt as PropertyKnowledgeRef,
        on_tag: "root",
        value,
      })),
    };
  }
  return queries;
}

export function isConceptPageLinkable(
  conceptType: ConceptKnowledgeRef,
  propertyType: PropertyKnowledgeRef
) {
  const keySet = keySetsForConceptType(conceptType).find(
    (ks) => ks.length == 1 && ks[0] === propertyType
  );
  return !!keySet;
}

export function conceptAddress(concept: GraphConcept): ConceptAddress | undefined {
  const keySets = keySetsForConceptType(concept.type);
  for (const keySet of keySets) {
    const keys: Record<string, unknown> = {};
    for (const ptype of keySet) {
      const prop = (concept.properties || []).find((p) => p.type === ptype);
      keys[ptype] = prop?.value;
    }
    if (every(Object.values(keys), (k) => isValue(k))) {
      return { conceptType: concept.type, keys: keys as ConceptKeySet };
    }
  }
  return undefined;
}

export function readerConceptTitle(concept: GraphConcept) {
  const appStore = useAppStore();
  const display = appStore.conceptDisplays[concept.type] ?? {};
  const props = concept.properties || [];
  for (const titleTemplate of display.titles ?? []) {
    const strings: string[] = [];
    for (const element of titleTemplate) {
      if (isString(element)) {
        strings.push(element);
      } else {
        const prop = props.find((prop) => prop.type === element.property_type);
        if (prop != undefined) strings.push(stringifyProperty(prop));
      }
    }
    if (strings.length === titleTemplate.length) return strings.join("");
  }
  return useKnowledge().conceptTitle(concept);
}

// Which sets of properties can be used as ConceptAddress keys for a concept type?
function keySetsForConceptType(conceptType: ConceptKnowledgeRef) {
  const appStore = useAppStore();
  const resolutions = getMapClausesWhere(
    appStore.map,
    MapSectionKey.Resolutions,
    (c) => c.type === conceptType
  );
  return Object.values(resolutions).map((r) => r.on);
}

import useGraph from "@/common/composables/useGraph";
import { httpClient as axios } from "@/common/http/http";
import { isValidModuleId, ModuleResponse } from "@/common/lib/api";
import {
  Async,
  asyncFailed,
  asyncInProgress,
  asyncNotStarted,
  asyncSucceeded,
} from "@/common/lib/async";
import { emptyGraph, Graph } from "@/common/lib/graph";
import { CTMap } from "@/common/lib/map";
import { useKnowledgeStore } from "@/common/stores/knowledgeStore";
import { defineStore } from "pinia";
import { ConceptDisplay } from "../lib/concept";
import { Page } from "../lib/page";
import { ExploreContext, useExploreStore } from "./explore";

export type SelectedTab = "user-settings" | "connection";
interface State {
  startupLoadingState: Async<null>;
  map: CTMap;
  metagraph: Graph;
  pages: Record<string, Page>;
  conceptDisplays: Record<string, ConceptDisplay>;
  moduleCache: Record<string, ModuleResponse>;
  module?: string;
  conceptColors: Record<string, string>;
  selectedTab: SelectedTab;
  userSelected: boolean;
  creatingPage: boolean;
}
export type SubPage = "modules" | "user-settings";

export const useAppStore = defineStore("reader-app", {
  state: (): State => ({
    startupLoadingState: asyncNotStarted(),
    map: {},
    metagraph: emptyGraph(),
    moduleCache: {},
    module: undefined,
    conceptColors: {},
    pages: {},
    conceptDisplays: {},
    selectedTab: "user-settings",
    userSelected: false,
    creatingPage: false,
  }),
  getters: {
    subPage: (state) => subPage(state.userSelected),
  },
  actions: {
    bootEmbedded(
      module: string,
      map: CTMap,
      metagraph: Graph,
      conceptColors: Record<string, string>
    ) {
      this.module = module;
      this.map = map;
      this.metagraph = metagraph;
      this.conceptColors = conceptColors;
      // We can probably stop storing these in two places...
      useExploreStore().configure(ExploreContext.Embedded, map, metagraph, conceptColors);
    },

    async bootStandalone(moduleId: string) {
      this.startupLoadingState = asyncInProgress("Starting up...");
      if (!isValidModuleId(moduleId)) {
        this.startupLoadingState = asyncFailed("The specified Module ID was not valid.");
        return;
      }
      const knowledgeStore = useKnowledgeStore();
      if (!knowledgeStore.initialized) {
        try {
          await knowledgeStore.load(moduleId);
        } catch (error) {
          this.startupLoadingState = asyncFailed("Startup couldn't be completed.");
          return;
        }
      }

      if (!this.moduleCache[moduleId]) {
        try {
          this.moduleCache[moduleId] = (await axios.get(`/api/projects/${moduleId}`)).data;
        } catch (error) {
          this.startupLoadingState = asyncFailed("Startup couldn't be completed.");
          return;
        }
      }
      this.module = moduleId;
      const module = this.moduleCache[moduleId];
      this.map = module.module.map;
      this.creatingPage = false;
      useKnowledgeStore().extractMapKnowledge(this.map);
      const exploreStore = useExploreStore();
      exploreStore.boot(moduleId);
      this.conceptColors = module.module.app_state?.conceptColors ?? {};
      this.pages = module.module.app_state?.readerPages ?? {};
      this.conceptDisplays = module.module.app_state?.conceptDisplays ?? {};
      exploreStore.configure(
        ExploreContext.Standalone,
        this.map,
        module.metagraph,
        this.conceptColors
      );

      const { metagraphWithoutRecords } = useGraph(() => module.metagraph);
      this.metagraph = metagraphWithoutRecords();

      this.startupLoadingState = asyncSucceeded(null);
    },
  },
});

function subPage(userSelected: boolean): SubPage {
  if (userSelected) {
    return "user-settings";
  }
  return "modules";
}

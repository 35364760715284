import { httpClient as axios } from "@/common/http/http";
import {
  Async,
  asyncFailed,
  asyncHasValue,
  asyncInProgress,
  asyncSucceeded,
  asyncValue,
} from "@/common/lib/async";
import { underlyingPropertyTypes } from "@/common/lib/derived";
import { convertRequestFormat } from "@/common/lib/derivedV2";
import { GraphConcept } from "@/common/lib/graph";
import { BASE_CONCEPT_TYPE } from "@/common/lib/knowledge";
import { CTMap } from "@/common/lib/map";
import {
  FetchNConcept,
  FetchNPropertySet,
  FetchNRequest,
  FetchNResponse,
  findPropertyDefByAlias,
  NON_NEIGHBORHOOD_PATH_KEYS,
  RootAndNeighborRefs,
} from "@/common/lib/query";
import { AxiosResponse } from "axios";
import { compact, flattenDeep, isObject, isString, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref, watchEffect } from "vue";

export interface UseQueryResult {
  aliases: FetchNPropertySet;
  root: GraphConcept;
  tags: Record<string, GraphConcept[]>;
}

export default function useQuery(module: string, query: () => FetchNRequest, map?: () => CTMap) {
  const queryResults: Ref<Async<FetchNResponse>> = ref(asyncInProgress());
  let latestQueryId = ""; // Guards against query races by ensuring only the latest result gets loaded

  watchEffect(async function () {
    const ourQueryId = uuidv4();
    latestQueryId = ourQueryId;
    queryResults.value = asyncInProgress();
    const request = { ...query(), map: map?.() };
    const requestV2 = convertRequestFormat(request);
    let response: AxiosResponse<FetchNResponse>;
    try {
      response = await axios.post(`/api/projects/${module}/query`, requestV2);
    } catch (error) {
      queryResults.value = asyncFailed("Couldn't load data.");
      return;
    }
    if (latestQueryId === ourQueryId) queryResults.value = asyncSucceeded(response.data);
  });

  function rootConcept() {
    if (!asyncHasValue(queryResults.value)) return emptyConcept();
    const results = asyncValue(queryResults.value)!;
    const rootId = results.paths[0].root_id;
    return dataToConcept(results.data[rootId]);
  }

  function rootConcepts() {
    if (!asyncHasValue(queryResults.value)) return [];
    const results = asyncValue(queryResults.value)!;
    return results.paths.map((path) => dataToConcept(results.data[path.root_id]));
  }

  const results = computed(function (): UseQueryResult[] {
    if (!asyncHasValue(queryResults.value)) return [];
    const ourQuery = query();
    const results = asyncValue(queryResults.value)!;
    return results.paths.map(function (refs: RootAndNeighborRefs) {
      const conceptIds: string[] = [refs.root_id];
      const conceptsByTag: Record<string, GraphConcept[]> = {};
      for (const [refKey, ref] of Object.entries(refs)) {
        if (!NON_NEIGHBORHOOD_PATH_KEYS.includes(refKey)) {
          const paths = ref as string[][];
          // Collect concepts from tagged neighbors
          ourQuery.neighbors![refKey].forEach(function (neighDef, i) {
            if (isObject(neighDef) && neighDef.tag != null) {
              conceptsByTag[neighDef.tag] = paths.map((path) =>
                dataToConcept(results.data[path[i]])
              );
            }
          });
          // ...and also dump all properties into a big bucket to be stored by alias
          conceptIds.push(...flattenDeep(paths).filter((_, i) => i % 2));
        }
      }
      const propertySets = conceptIds.map((ref) => results.data[ref].properties);
      return {
        aliases: merge({}, ...propertySets),
        root: dataToConcept(results.data[refs.root_id]),
        tags: conceptsByTag,
      };
    });
  });

  const isDone = computed(() => asyncHasValue(queryResults.value));

  const isEmpty = computed(() => asyncValue(queryResults.value)!.paths.length == 0);

  function dataToConcept(data: FetchNConcept): GraphConcept {
    const ourQuery = query();
    return {
      id: "",
      type: data.concept_type,
      properties: Object.entries(data.properties).flatMap(function ([alias, values]) {
        return compact(
          values.map(function (value) {
            const propDef = findPropertyDefByAlias(ourQuery, alias)!;
            if (!isString(propDef)) return null; // Leave derived properties out of this
            return { id: "", type: underlyingPropertyTypes(propDef)[0], value };
          })
        );
      }),
    };
  }

  function emptyConcept(): GraphConcept {
    return { id: "", type: BASE_CONCEPT_TYPE, properties: [] };
  }

  return {
    queryResults,
    results,
    rootConcept,
    rootConcepts,
    isDone,
    isEmpty,
  };
}

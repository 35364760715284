<template>
  <div class="absolute inset-0 flex flex-col dark:bg-gray-800">
    <TabSelect
      :tabs="tabs"
      v-model="exploreStore.activeTool"
      class="border-b border-b-gray-200 light:bg-gray-100 dark:border-b-gray-900"
    />
    <div class="basis-full overflow-y-auto p-10">
      <div v-if="exploreStore.activeTool === Tool.Concept">
        <template v-if="exploreStore.conceptPage">
          <ConceptPage
            :address="exploreStore.conceptPage"
            @navigate="exploreStore.showConceptPage"
            @pivot="pivot"
          />
        </template>
        <div class="p-20 text-center text-xl text-gray-500" v-else>
          Click on an underlined value and choose the "View" menu item to see Concept details here.
        </div>
      </div>
      <div v-if="exploreStore.activeTool === Tool.Insights">
        <template v-if="visualizations.length > 0">
          <Visualization
            v-for="v in visualizations"
            :key="v.key"
            :visualization="v.visualization"
          />
        </template>
        <div class="p-20 text-center text-xl text-gray-500" v-else>
          No visualizations are available for the current perspective.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TabSelect from "@/common/components/TabSelect.vue";
import { computed } from "vue";
import { Tool, useExploreStore } from "@/reader/stores/explore";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import { Neighborhood, PropertyFilter } from "@/common/lib/query";
import { ExploreFilter, buildQuery, filterWithDefaults } from "@/reader/lib/explore";
import ConceptPage from "../concept/ConceptPage.vue";
import { suggestedVisualizations } from "@/reader/lib/visualization";
import Visualization from "./Visualization.vue";
import { omit } from "lodash";

const exploreStore = useExploreStore();

const tabs = computed(() => [
  { label: "Insights", key: Tool.Insights },
  { label: "Concept", key: Tool.Concept },
]);

function pivot(ctype: ConceptKnowledgeRef, filters: PropertyFilter[], path: Neighborhood) {
  // This will fail on multi-key merges
  const efilter: ExploreFilter = filterWithDefaults({
    type: filters[0].type,
    property_type: filters[0].property_type,
    values: [omit(filters[0], "type", "property_type", "on_tag")],
    neighborhood: path,
  });
  exploreStore.pivot(ctype, efilter);
}

const visualizations = computed(function () {
  if (exploreStore.root_concept_type == null) return [];
  const [query] = buildQuery();
  const visMap = suggestedVisualizations(query, exploreStore.metagraph);
  return Object.entries(visMap).map(([key, visualization]) => ({ key, visualization }));
});
</script>

import { httpClient as axios } from "@/common/http/http";
import { Async, asyncFailed, AsyncStatus } from "@/common/lib/async";
import { FailureType } from "@/common/lib/failure";
import { CTMap } from "@/common/lib/map";
import { ExploreBookmark } from "@/reader/lib/exploreBookmark";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { asyncInProgress, asyncNotStarted, asyncSucceeded } from "../lib/async";
import { useFailureStore } from "./failureStore";

export type ModuleMetadata = {
  id: string;
  moduleType: ModuleType;
  name: string;
  updated: DateTime;
  thumbnail: string;
  thumbnail_url?: string;
  owned?: boolean;
  owner_name?: string;
  knowledge_version: string;
  databricks_host?: string;
  module_version: number;
  created: DateTime;
  description?: string;
  published_reader_view: boolean;
  bookmarks?: Bookmark[];

  // View-only
  selected?: boolean;
};

export type Bookmark = {
  id: string;
  metadata: {
    name: string;
    description?: string;
    updated: DateTime;
    created: DateTime;
  };
  state: ExploreBookmark;
};

interface State {
  modules: Async<ModuleMetadata[]>;
  sharedModules: Async<ModuleMetadata[]>;
  localModules: Async<ModuleMetadata[]>;
  readerModules: Async<ModuleMetadata[]>;
  bookmarks: Async<Bookmark[]>;
}

interface UserBookmark {
  id: string;
  name: string;
  description?: string;
  state: ExploreBookmark;
}
type CreateUserBookmark = Omit<UserBookmark, "id">;
type UpdateUserBookmark = Omit<UserBookmark, "state">;

export type ModuleType =
  | "user" // regular user module
  | "shared" // a published shared module
  | "local" // a locally exported module
  | "reader"; // a reader module

export function moduleHasType(module: ModuleMetadata, ...type: ModuleType[]): boolean {
  return type.includes(module.moduleType);
}

export const useUserModuleStore = defineStore("home-user-module", {
  state: (): State => ({
    modules: asyncNotStarted(),
    sharedModules: asyncNotStarted(),
    localModules: asyncNotStarted(),
    readerModules: asyncNotStarted(),
    bookmarks: asyncNotStarted(),
  }),
  getters: {},
  actions: {
    async boot() {
      this.modules = asyncInProgress("Loading projects…");
      this.modules = await fetchSorted("/api/projects", "user", "projects");
    },
    async newModule() {
      const createResponse = await axios.post("/api/projects");
      return createResponse.data.id;
    },
    async updateModule(
      moduleId: string,
      params: {
        name?: string;
        map?: CTMap;
        thumbnail?: Blob;
        dependencies?: string[];
        published_reader_view?: boolean;
      }
    ) {
      return await axios.patch(`/api/projects/${moduleId}`, params);
    },
    async renameModule(
      moduleId: string,
      oldModuleName: string,
      newModuleName: string
    ): Promise<string | undefined> {
      if (oldModuleName === newModuleName) {
        // Do not rename to same name.
        return;
      }
      if (newModuleName.trim() === "") {
        // Do not rename to empty string
        return;
      }
      const params = {
        name: newModuleName,
      };
      const response = await this.updateModule(moduleId, params);
      const newName = response.data["name"];
      if (newName && this.modules.status === AsyncStatus.Succeeded) {
        const module = this.modules.result.find((mod) => mod.id === moduleId);
        if (module) {
          module.name = newName;
        }
      }
      return newName;
    },
    async duplicateModule(moduleId: string, newModuleName: string) {
      const oldModules = this.modules;
      this.modules = asyncInProgress("Duplicating project…");
      const params = { module_name: newModuleName };
      try {
        await axios.post(`/api/projects/${moduleId}/duplicate`, params);
        await this.boot();
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to duplicate project",
          error,
        });
        this.modules = oldModules;
      }
    },
    async deleteModule(moduleId: string) {
      const oldModules = this.modules;
      this.modules = asyncInProgress("Deleting project…");
      try {
        await axios.delete(`/api/projects/${moduleId}`);
        await this.boot();
      } catch (error: unknown) {
        useFailureStore().backendFail({
          type: FailureType.Api,
          description: "Failed to delete project",
          error,
        });
        this.modules = oldModules;
      }
    },
    async loadShared() {
      this.sharedModules = asyncInProgress("Loading shared projects…");
      this.sharedModules = await fetchSorted("/api/shared-projects", "shared", "shared projects");
    },
    async duplicateSharedModule(moduleId: string): Promise<string> {
      const response = await axios.post(`/api/shared-projects/${moduleId}`);
      return response.data.id;
    },
    async deleteSharedModule(moduleId: string) {
      return await axios.delete(`/api/shared-projects/${moduleId}`);
    },
    async publishModule(moduleId: string) {
      await axios.post(`/api/projects/${moduleId}/publish`);
    },
    async loadLocal() {
      this.localModules = asyncInProgress("Loading exported projects…");
      this.localModules = await fetchSorted("/api/local-projects", "local", "exported projects");
    },
    async duplicateLocalModule(moduleId: string): Promise<string> {
      const response = await axios.post(`/api/local-projects/${moduleId}`);
      return response.data.id;
    },
    async publishLocalModule(moduleId: string) {
      await axios.post(`/api/projects/${moduleId}/publish-local`);
    },
    async publishReaderView(moduleId: string, isPublished: boolean) {
      const params = {
        published_reader_view: !isPublished,
      };
      await this.updateModule(moduleId, params);
    },
    async loadReader() {
      this.readerModules = asyncInProgress("Loading Reader projects…");
      this.readerModules = await fetchSorted(
        "/api/projects?mode=reader",
        "reader",
        "reader modules"
      );
    },
    async newBookmark(moduleId: string, params: CreateUserBookmark) {
      const response = await axios.post(`/api/projects/${moduleId}/bookmarks`, params);
      return response.data.id;
    },
    async loadProjectBookmarks(moduleId: string) {
      this.bookmarks = asyncInProgress("Loading bookmarks…");
      this.bookmarks = await fetchBookmarks(`/api/projects/${moduleId}/bookmarks`);
    },
    async getBookmark(moduleId: string, bookmarkId: string) {
      const response = await axios.get(`/api/projects/${moduleId}/bookmarks/${bookmarkId}`);
      return response.data;
    },
    async deleteBookmark(moduleId: string, bookmarkId: string) {
      const response = await axios.delete(`/api/projects/${moduleId}/bookmarks/${bookmarkId}`);
      return response.data.id;
    },
    async updateBookmark(moduleId: string, bookmarkId: string, params: UpdateUserBookmark) {
      const response = await axios.patch(
        `/api/projects/${moduleId}/bookmarks/${bookmarkId}`,
        params
      );
      return response.data.id;
    },
  },
});

function parseModuleType(moduleType: ModuleType) {
  return (module: ModuleMetadata & { updated: string; created: string }) =>
    parseModule(moduleType, module);
}

function parseModule(
  moduleType: ModuleType,
  module: ModuleMetadata & { updated: string; created: string }
): ModuleMetadata {
  // const deletable = moduleType === "user" || (moduleType == "shared" && module.owned);
  // const publishable = moduleType === "user";
  return Object.assign(module, {
    moduleType,
    updated: DateTime.fromISO(module.updated),
    created: DateTime.fromISO(module.created),
    // deletable,
    // publishable,
  });
}

async function fetchSorted(
  url: string,
  moduleType: "user" | "shared" | "local" | "reader",
  label: string
) {
  try {
    const modulesResponse = await axios.get(url);
    return asyncSucceeded(
      orderBy(
        modulesResponse.data.modules.map(parseModuleType(moduleType)),
        (m) => m.updated,
        "desc"
      )
    );
  } catch (error: unknown) {
    return asyncFailed(`Failed to load ${label}`);
  }
}

async function fetchBookmarks(url: string) {
  try {
    const bookmarksResponse = await axios.get(url);
    return asyncSucceeded(
      orderBy(bookmarksResponse.data.bookmarks, (m) => m.metadata.created, "desc")
    );
  } catch (error: unknown) {
    return asyncFailed(`Failed to load`);
  }
}

import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { asyncValue } from "@/common/lib/async";
import {
  AGGREGATE_OP_TYPES,
  DerivedPropertyTerm,
  propertyName,
  PropertyOpType,
} from "@/common/lib/derived";
import { formatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { LinkDescriptor } from "@/common/lib/graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import { getMapClausesWhere, MapSectionKey } from "@/common/lib/map";
import {
  FetchNOrderBy,
  FetchNRequest,
  FetchNResponse,
  Filter,
  GROUP_BY_ALL,
  Neighborhood,
  normalizeNeighborhood,
  PathNode,
  PropertyFilter,
  RootAndNeighborRefs,
} from "@/common/lib/query";
import { GraphValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import {
  chunk,
  cloneDeep,
  compact,
  findKey,
  flatten,
  fromPairs,
  isArray,
  isEqual,
  isObject,
  isString,
  last,
  sortBy,
} from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ExploreTreePath } from "./exploreTree";
import { expandReaderFilter, filterIsComplete, ReaderFilter } from "./filter";

export interface ExploreColumn {
  alias: string;
  property_type: DerivedPropertyTerm;
  // If no neighborhood, this property lives on the root concept.
  // No tags or properties[] needed - these will be calculated by buildNeighborhoods
  neighborhood?: Neighborhood;
  displayName?: string;
}

export interface ExploreColumnStats {
  max: GraphValue;
}

export interface ExploreFilter<T extends PropertyFilter = PropertyFilter> extends ReaderFilter<T> {
  neighborhood?: Neighborhood;
}

export interface ExploreGroupBy {
  property_type: DerivedPropertyTerm;
  neighborhood?: Neighborhood;
}

export interface ExploreOrderBy {
  on: string; // a column alias
  asc: boolean;
}

export interface ExploreCell {
  values: ValueWithFormattedValue[];
  isTruncated: boolean;
}

export type ExploreRow = Record<string, ExploreCell>;
export type ExploreTable = ExploreRow[];

// This is a remnant of an earlier time and can be factored out
export type IdMap = Record<
  string,
  {
    neighborhood: string;
    position: number;
  }
>;

export function buildQuery(): [FetchNRequest, IdMap] {
  const exploreStore = useExploreStore();
  const [neighbors, idMap] = buildNeighborhoods();
  const query: FetchNRequest = {
    concept_type: exploreStore.root_concept_type!,
    neighbors,
    size: 100,
    order_by: exploreStore.order_by.map((ob) => buildOrderBy(ob, idMap)),
    group_by:
      exploreStore.group_by === GROUP_BY_ALL
        ? GROUP_BY_ALL
        : exploreStore.group_by.filter((g) => g.neighborhood == null).map((g) => g.property_type),
    properties: fromPairs(
      exploreStore.columns
        .filter((c) => c.neighborhood == null)
        .map((c) => [c.alias, c.property_type])
    ),
    filters: exploreStore.filters.filter(filterIsComplete).map((ef) => buildFilter(ef, idMap)),
    columns: exploreStore.columns.map((column) => ({
      alias: column.alias,
      name: columnName(column),
    })),
  };
  return [query, idMap];
}

function buildNeighborhoods(): [Record<string, Neighborhood>, IdMap] {
  const exploreStore = useExploreStore();
  const neighborhoods: Record<string, Neighborhood> = {};
  const idMap: IdMap = {};

  function neighborhoodToPath(neighborhood: Neighborhood) {
    return neighborhood.map((node) => (isObject(node) ? node.concept_type : node));
  }

  function addNeighborhood(
    neighborhood: Neighborhood,
    endpointAttrs: Omit<PathNode, "concept_type" | "tag"> = {}
  ) {
    const path = neighborhoodToPath(neighborhood);
    let neighborhoodKey = findKey(neighborhoods, (neighborhood) =>
      isEqual(neighborhoodToPath(neighborhood), path)
    );
    if (neighborhoodKey == null) {
      neighborhoodKey = uuidv4();
      const toAdd = cloneDeep(neighborhood);
      toAdd[path.length - 1] = {
        concept_type: last(path) as ConceptKnowledgeRef,
        tag: generateTagName(neighborhoodKey, path.length - 1),
        ...endpointAttrs,
      };
      neighborhoods[neighborhoodKey] = toAdd;
    } else {
      // Merge existing and new endpoint attributes
      const node = neighborhoods[neighborhoodKey][path.length - 1] as PathNode;
      if (endpointAttrs.group_by != null) {
        if (endpointAttrs.group_by === GROUP_BY_ALL || node.group_by === GROUP_BY_ALL) {
          node.group_by = GROUP_BY_ALL;
        } else {
          node.group_by = [...(node.group_by ?? []), ...endpointAttrs.group_by];
        }
      }
      if (endpointAttrs.properties) {
        node.properties = { ...endpointAttrs.properties, ...(node.properties || {}) };
      }
    }
    return neighborhoodKey;
  }
  for (const column of exploreStore.columns) {
    if (column.neighborhood) {
      const nkey = addNeighborhood(column.neighborhood, {
        properties: { [column.alias]: column.property_type },
      });
      idMap[column.alias] = { neighborhood: nkey, position: column.neighborhood.length - 1 };
    }
  }
  for (const filter of exploreStore.filters) {
    if (filter.neighborhood) {
      const nkey = addNeighborhood(filter.neighborhood);
      idMap[filter.alias] = { neighborhood: nkey, position: filter.neighborhood.length - 1 };
    }
  }
  if (isArray(exploreStore.group_by)) {
    for (const group of exploreStore.group_by) {
      if (group.neighborhood) {
        addNeighborhood(group.neighborhood, { group_by: [group.property_type] });
      }
    }
  }
  return [neighborhoods, idMap];
}

function buildFilter(expFilter: ExploreFilter, idMap: IdMap): Filter {
  const tag = expFilter.neighborhood != null ? idMap[expFilter.alias] : undefined;
  const on_tag = tag ? generateTagName(tag.neighborhood, tag.position) : undefined;
  return expandReaderFilter(expFilter, on_tag);
}

function buildOrderBy(expOrderBy: ExploreOrderBy, idMap: IdMap): FetchNOrderBy {
  const exploreStore = useExploreStore();
  const column = exploreStore.columnByAlias(expOrderBy.on);
  if (column == null) throw `Order by column missing (${expOrderBy.on})`;
  const tag = column.neighborhood != null ? idMap[column.alias] : undefined;
  return {
    ...expOrderBy,
    on_tag: tag ? generateTagName(tag.neighborhood, tag.position) : undefined,
  };
}

export function buildExploreTable(response: FetchNResponse, idMap: IdMap): ExploreTable {
  const columnDefs = useExploreStore().columns;
  return response.paths.map(function (refs: RootAndNeighborRefs) {
    const rootConcept = response.data[refs.root_id];
    return fromPairs(
      columnDefs.map(function (columnDef) {
        const mapping = idMap[columnDef.alias];
        let concepts;
        if (mapping) {
          const neighborhoods = (refs[mapping.neighborhood] as string[][]) || [];
          concepts = neighborhoods.map((neigh) => response.data[neigh[mapping.position]]);
        } else {
          concepts = [rootConcept];
        }
        const props = concepts.flatMap((concept) => concept?.properties[columnDef.alias] ?? []);
        const values = compact(props).map((prop) => formatValue(columnDef.property_type, prop));
        const isTruncated = rootConcept.truncated?.includes(columnDef.alias);
        return [columnDef.alias, { values, isTruncated }];
      })
    );
  });
}

export function rootColumns(): ExploreColumn[] {
  const exploreStore = useExploreStore();
  const { typeLabel } = useKnowledge();
  const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);
  const rootConceptType = exploreStore.root_concept_type!;
  const rootMetaconcept = getConceptsOfType(rootConceptType)[0];
  const props = sortBy(rootMetaconcept.properties || [], (p) => typeLabel(p.type).trim());
  return props.map((metaprop) => ({
    alias: metaprop.id,
    property_type: metaprop.type,
  }));
}

export function buildSimpleColumn(
  conceptPath: ExploreTreePath,
  propertyType: PropertyKnowledgeRef,
  op?: PropertyOpType
): ExploreColumn {
  let ourPropType: DerivedPropertyTerm;
  if (op) {
    ourPropType = {
      op,
      property_type: propertyType,
    } as DerivedPropertyTerm;
  } else {
    ourPropType = propertyType;
  }
  return {
    alias: uuidv4(),
    property_type: ourPropType,
    neighborhood: buildNeighborhoodPath(conceptPath),
  };
}

export function buildCountColumn(conceptPath: ExploreTreePath): ExploreColumn {
  return {
    alias: uuidv4(),
    property_type: { op: PropertyOpType.Count, approx: false },
    neighborhood: buildNeighborhoodPath(conceptPath),
  };
}

export function buildNeighborhoodPath(conceptPath: ExploreTreePath): Neighborhood | undefined {
  if (conceptPath.length === 1) return undefined;
  return conceptPath.slice(1).flatMap(function (element) {
    return [element.linkDescriptor!, { concept_type: element.conceptType }];
  });
}

export function initialColumnSet(): ExploreColumn[] {
  const exploreStore = useExploreStore();
  if (exploreStore.group_by === GROUP_BY_ALL) {
    return [buildCountColumn([{ conceptType: exploreStore.root_concept_type! }])];
  } else if (exploreStore.group_by.length > 0) {
    const groups = exploreStore.group_by.map((group) => ({
      alias: uuidv4(),
      property_type: group.property_type,
      neighborhood: group.neighborhood,
    }));
    return [...groups, buildCountColumn([{ conceptType: exploreStore.root_concept_type! }])];
  } else {
    return rootColumns(); // for now!
  }
}

export function initialOrderBy() {
  const exploreStore = useExploreStore();
  const ordering: FetchNOrderBy[] = [];
  if (exploreStore.group_by.length > 0) {
    const countCol = exploreStore.columns.find(
      (c) => isObject(c.property_type) && c.property_type.op === PropertyOpType.Count
    );
    if (countCol != null) ordering.push({ on: countCol.alias, asc: false });
  }
  return ordering;
}

export function availablePivots(column: ExploreColumn) {
  const exploreStore = useExploreStore();
  const metagraph = useGraph(() => exploreStore.metagraph).metagraphWithoutRecords();
  const { getMetaPath } = useGraph(() => metagraph);
  // What concept is our filter property on?
  let pivotFrom: string;
  if (column.neighborhood == null) {
    pivotFrom = exploreStore.root_concept_type!;
  } else {
    const lastNode = column.neighborhood[column.neighborhood.length - 1] as PathNode;
    pivotFrom = lastNode.concept_type;
  }
  // Generate paths to all the other concepts
  // Ignore ones that don't have properties. Is that a good heuristic? Who knows,
  // but it looks good right now as it hides empty roles
  const candidates = metagraph.concepts
    .filter((mc) => mc.type !== exploreStore.root_concept_type && (mc.properties || []).length > 0)
    .map((mc) => mc.type);
  return compact(
    candidates.map(function (pivotTo) {
      const path = getMetaPath(pivotTo, pivotFrom, true);
      if (path == null) return null;
      return { to: pivotTo, neighborhood: path.length === 0 ? undefined : path };
    })
  );
}

export function columnName(column: ExploreColumn): string {
  return propertyName(column.property_type, column.neighborhood, column.displayName);
}

export function neighborhoodConceptType(neighborhood?: Neighborhood) {
  if (neighborhood == null) return useExploreStore().root_concept_type!;
  return (last(normalizeNeighborhood(neighborhood)) as PathNode).concept_type;
}

export function calculateColumnStats() {
  const exploreStore = useExploreStore();
  const columns: Record<string, ExploreColumnStats> = {};
  const table = asyncValue(exploreStore.table)!;
  for (const column of exploreStore.columns) {
    if (!isString(column.property_type) && AGGREGATE_OP_TYPES.includes(column.property_type.op)) {
      const values = flatten(table.map((row) => row[column.alias].values)).map(
        (v) => v.originalValue.value as number
      );
      columns[column.alias] = { max: toValue(Math.max(...values)) };
    }
  }
  return columns;
}

export function findCurrentColumn(column: Pick<ExploreColumn, "property_type" | "neighborhood">) {
  const exploreStore = useExploreStore();
  const col = exploreStore.columns.find(
    (candidate) =>
      isEqual(candidate.property_type, column.property_type) &&
      isEqual(candidate.neighborhood, column.neighborhood)
  );
  return col?.alias;
}

// These are quick-selectable from a menu as they require no config
export const SIMPLE_COLUMN_OPS = [
  PropertyOpType.Sum,
  PropertyOpType.Min,
  PropertyOpType.Max,
  PropertyOpType.Avg,
  PropertyOpType.Median,
];

export function isEquivalentNeighborhood(n1: Neighborhood = [], n2: Neighborhood = []) {
  if (n1.length !== n2.length) return false;
  for (let i = 0; i != n1.length; i++) {
    const string1 = isString(n1[i]) ? n1[i] : (n1[i] as PathNode).concept_type;
    const string2 = isString(n2[i]) ? n2[i] : (n2[i] as PathNode).concept_type;
    if (string1 !== string2) return false;
  }
  return true;
}

// Returns a set of tree paths on the way to the provided neighborhood
export function treePathsToNeighborhood(neighborhood: Neighborhood) {
  const exploreStore = useExploreStore();
  const elements = chunk(neighborhood, 2);
  const paths: ExploreTreePath[] = [];
  for (let i = 0; i < elements.length + 1; i++) {
    const section = elements.slice(0, i);
    paths.push([
      { conceptType: exploreStore.root_concept_type! },
      ...section.map((e) => ({
        linkDescriptor: e[0] as LinkDescriptor,
        conceptType: isString(e[1]) ? (e[1] as ConceptKnowledgeRef) : e[1].concept_type,
      })),
    ]);
  }
  return paths;
}

// Which sets of properties can be used as ConceptAddress keys for a concept type?
export function keySetsForConcept(conceptType: ConceptKnowledgeRef) {
  const exploreStore = useExploreStore();
  const resolutions = getMapClausesWhere(
    exploreStore.map!,
    MapSectionKey.Resolutions,
    (c) => c.type === conceptType
  );
  return Object.values(resolutions).map((r) => r.on);
}

export function filterWithDefaults(
  filter: Partial<ExploreFilter> & Pick<ExploreFilter, "type" | "property_type">
): ExploreFilter {
  return {
    alias: uuidv4(),
    values: [],
    negated: false,
    ...filter,
  };
}

function generateTagName(neighborhoodKey: string, position: number) {
  return `${neighborhoodKey}_${position}`;
}

import { DateTime } from "luxon";
import useKnowledge from "../composables/useKnowledge";
import { DerivedPropertyTerm, underlyingPropertyTypes } from "./derived";
import { GraphCompoundValue, stringifyValueOrCompositeValue } from "./graph";
import {
  DatetimeValue,
  DateValue,
  FloatValue,
  GraphValue,
  IntegerValue,
  isValue,
  toValue,
} from "./value";

export interface ValueWithFormattedValue {
  originalValue: GraphValue | GraphCompoundValue;
  formattedValue: GraphValue;
}

export function formatValue(
  propType: DerivedPropertyTerm,
  value: GraphValue | GraphCompoundValue,
  overrideTransformer?: string
): ValueWithFormattedValue {
  const ov = { originalValue: value };
  const transformer = overrideTransformer ?? transformerForPropType(propType);
  if (transformer) return { ...ov, formattedValue: TRANSFORMERS[transformer](value) };
  if (!isValue(value)) {
    // it's compound; format it as a string as best we can for now
    return { ...ov, formattedValue: toValue(stringifyValueOrCompositeValue(value)) };
  }
  return { ...ov, formattedValue: value as GraphValue };
}

export function noOpFormatValue(value: GraphValue): ValueWithFormattedValue {
  return {
    originalValue: value,
    formattedValue: value,
  };
}

// This stands in hackily for something the knowledge will soon be doing
const PROP_TYPE_TO_TRANSFORMER: Record<string, string> = {
  "property._.amount": "dollars",
  "property.auto.mileage": "roundNumber",
  "property.auto.depr_06m": "percent", // How come these don't share
  "property.auto.depr_12m": "percent", // a common parent?
  "property.cal.year": "year",
  "property._.number": "lowPrecisionNumber",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TransformerFnType = (arg0: any) => GraphValue;
export const TRANSFORMERS: Record<string, TransformerFnType> = {
  dollars: (number: IntegerValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      }).format(number.value)
    ),
  percent: (number: FloatValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 0,
      }).format(number.value)
    ),
  percentChange: (number: FloatValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: 0,
        signDisplay: "exceptZero",
      }).format(number.value)
    ),
  roundNumber: (number: IntegerValue | FloatValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(number.value)
    ),
  year: (number: IntegerValue | FloatValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        useGrouping: false,
      }).format(number.value)
    ),
  dateYM: (date: DateValue | DatetimeValue) =>
    toValue(DateTime.fromISO(date.value).toFormat("LLL yyyy")),
  lowPrecisionNumber: (number: IntegerValue | FloatValue) =>
    toValue(
      Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(number.value)
    ),
};

function transformerForPropType(propType: DerivedPropertyTerm) {
  const { isAncestorOf } = useKnowledge();
  const propKnowledgeRef = underlyingPropertyTypes(propType)[0];
  if (propKnowledgeRef != null) {
    for (const candidateParentPropType of Object.keys(PROP_TYPE_TO_TRANSFORMER)) {
      if (isAncestorOf(propKnowledgeRef, candidateParentPropType)) {
        return PROP_TYPE_TO_TRANSFORMER[candidateParentPropType];
      }
    }
  }
  return undefined;
}

import { ConceptDisplay } from "@/reader/lib/concept";
import { Page } from "@/reader/lib/page";
import { DocumentHeader, SourceDocument } from "./document";
import { Graph, GraphProperty } from "./graph";
import { ConceptKnowledgeRef } from "./knowledge";
import { ConceptPropertyPair, CTMap } from "./map";
import { BytesValue } from "./value";

interface DocumentSetIntermediate {
  headers: DocumentHeader[];
  documents: SourceDocument[];
}
export interface TableIntermediate {
  table_description?: string;
  rows?: number;
  bytes?: number;
}
interface GraphIntermediate {
  graph: Graph;
  metagraph: Graph;
}

export interface Intermediates {
  iterate_in: Record<string, Graph[]>;
  enrich?: GraphIntermediate;
  resolve?: GraphIntermediate;
  map_in_properties?: Record<string, GraphProperty[][]>;
  map_in?: GraphIntermediate;
  transform_in?: Record<string, DocumentSetIntermediate>;
  in_connections?: Record<string, TableIntermediate>;
  in_encoding?: Record<string, DocumentSetIntermediate>;
  out_documents: Record<string, DocumentSetIntermediate>;
}

export interface RunnerOutput {
  map: CTMap;
  intermediates: Intermediates;
}

export interface Environ {
  "map-path": string | null;
  "app-version": string;
}

export interface ApplicableEnrichment {
  id: string;
  signature: string;
  metagraph: Graph;
  depends_on?: string[];
}

export interface ApplicableEnrichmentsResponse {
  applicables: ApplicableEnrichment[];
}

export function blobPath(bytesValue: BytesValue) {
  return `/api/blob/${bytesValue.value}`;
}

export interface JobResponse {
  id: number;
  run_page_url?: string;
  state: JobState;
}

interface JobState {
  life_cycle_state: JobLifeCycleState;
  result_state: JobResultState;
  state_messate: string;
}

export enum JobLifeCycleState {
  Pending = "PENDING",
  Running = "RUNNING",
  Terminated = "TERMINATED",
}

export enum JobResultState {
  Success = "SUCCESS",
  Failed = "FAILED",
  TimedOut = "TIMEDOUT",
  Canceled = "CANCELED",
}

export function isValidModuleId(moduleId: string) {
  return !!moduleId.match(/^[a-z0-9_-]+$/i);
}

export interface ModuleResponse {
  module: Module;
  metagraph: Graph;
}

export interface Module {
  manifest: {
    id: string;
    name: string;
    published_reader_view?: boolean;
  };
  map: CTMap;
  thumbnail?: string;
  app_state?: {
    conceptColors?: Record<string, string>;
    bannedAutomerges?: ConceptPropertyPair[];
    readerPages?: Record<string, Page>;
    conceptDisplays?: Record<ConceptKnowledgeRef, ConceptDisplay>;
  };
}

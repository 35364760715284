import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import {
  DerivedPropertyTerm,
  propertyName,
  PropertyOpType,
  propertyValueType,
} from "@/common/lib/derived";
import { formatValue, noOpFormatValue, ValueWithFormattedValue } from "@/common/lib/format";
import { Graph, GraphCompoundValue, GraphConcept, LinkDescriptor } from "@/common/lib/graph";
import {
  COMPOSITE_PROPERTY_VALUE_TYPE,
  ConceptKnowledgeRef,
  PropertyKnowledgeRef,
} from "@/common/lib/knowledge";
import { FetchNRequest, findPropertyDefByAlias, Neighborhood } from "@/common/lib/query";
import { GraphValue, GraphValueType, isValue, toValue } from "@/common/lib/value";
import { chunk, cloneDeep, isObject, isString, mapValues, pick } from "lodash";
import { UseQueryResult } from "../composables/useQuery";
import { readerConceptTitle } from "./concept";

export enum VisualizationType {
  DiscreteDistribution = "discrete_distribution",
  TimeDistribution = "time_distribution",
  Indicator = "indicator",
  PieChart = "pie_chart",
  Table = "table",
  Sankey = "sankey",
  FinancialStatement = "financial_statement",
}

interface BaseVisualization {
  type: VisualizationType;
  title: string; // Maybe this is eventually optional
  query: Partial<FetchNRequest>;
  config: Record<string, unknown>;
}

export interface DiscreteDistributionVisualization extends BaseVisualization {
  type: VisualizationType.DiscreteDistribution;
  config: {
    category: string;
    category_name?: ValueGenerator; // If not specified, category is used
    value: ValueGenerator;
  };
}

export interface TimeDistributionVisualization extends BaseVisualization {
  type: VisualizationType.TimeDistribution;
  config: {
    time: string;
    value: string;
  };
}

export interface PieChartVisualization extends BaseVisualization {
  type: VisualizationType.PieChart;
  config: {
    category: string;
    category_name?: ValueGenerator; // If not specified, category is used
    value: ValueGenerator;
  };
}

export interface IndicatorVisualization extends BaseVisualization {
  type: VisualizationType.Indicator;
  config: {
    value: ValueGenerator;
  };
}

export interface TableVisualizationGroup {
  category: string;
  category_name?: ValueGenerator; // If not specified, category is used
}

export interface TableVisualization extends BaseVisualization {
  type: VisualizationType.Table;
  config: {
    columns: Array<{
      value: ValueGenerator;
      label?: string; // If left out, we'll try to determine one automatically
    }>;
    groups?: TableVisualizationGroup[];
  };
}

export interface FinancialStatementRow {
  label: string;
  id?: string; // Give this row an ID so as to refer to it in a compareToId
  compareToId?: string;
  value: ValueGenerator;
  highlight?: boolean;
  contents?: FinancialStatementRow[];
}

export interface FinancialStatementVisualization extends BaseVisualization {
  type: VisualizationType.FinancialStatement;
  config: {
    rows: FinancialStatementRow[];
    columns: ValueGenerator;
  };
}

export interface SankeyVisualization extends BaseVisualization {
  type: VisualizationType.Sankey;
  config: {
    links: Array<{
      from: string;
      to: string;
      value: ValueGenerator;
    }>;
    transformer?: string;
  };
}

export type Visualization =
  | DiscreteDistributionVisualization
  | TimeDistributionVisualization
  | IndicatorVisualization
  | PieChartVisualization
  | TableVisualization
  | SankeyVisualization;

export enum ValueGeneratorType {
  Property = "property",
  Title = "title",
}

interface BaseValueGenerator {
  type: ValueGeneratorType;
}

interface PropertyValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Property;
  alias: string;
  transformer?: string; // Temporary - hold your nose and take a look at format.ts' TRANSFORMERS
}

interface TitleValueGenerator extends BaseValueGenerator {
  type: ValueGeneratorType.Title;
  tag?: string; // If not specified, root concept is used
}

type NormalizedValueGenerator = PropertyValueGenerator | TitleValueGenerator;

// If you specify a string, it should be an alias (and you get a PropertyValueGenerator)
export type ValueGenerator = NormalizedValueGenerator | string;

export function suggestedVisualizations(
  query: FetchNRequest,
  metagraph: Graph
): Record<string, Visualization> {
  const { getKnowledgeItem } = useKnowledge();
  const conceptType = getKnowledgeItem(query.concept_type);
  const suggestedVisualizations =
    (conceptType.extra?.["suggested_visualizations"] as Record<string, Visualization>) ?? {};
  const resolvedVisualizations: Record<string, Visualization> = {};
  for (const [visId, vis] of Object.entries(suggestedVisualizations)) {
    const resolved = resolveVisualizationToMetagraph(vis, query.concept_type, metagraph);
    if (resolved != null) {
      // Add base query filters (and neighbors to support filters)
      resolved.query.filters = [...(resolved.query.filters ?? []), ...(query.filters ?? [])];
      resolved.query.neighbors = {
        ...resolved.query.neighbors,
        ...mapValues(
          query.neighbors,
          (path) => path.map((step) => (isObject(step) ? pick(step, "concept_type", "tag") : step)) // Don't fetch properties
        ),
      };
      resolvedVisualizations[visId] = resolved;
    }
  }
  return resolvedVisualizations;
}

// Try to match all elements of a visualization query to graph elements present
// in the metagraph. If we can't, return null
function resolveVisualizationToMetagraph(
  vis: Visualization,
  conceptType: ConceptKnowledgeRef,
  metagraph: Graph
): Visualization | null {
  const { isAncestorOf } = useKnowledge();
  const { getLinkPartners, getConcept } = useGraph(() => metagraph);
  vis = cloneDeep(vis);

  // Find root concept
  const root = metagraph.concepts.find((mc) => mc.type === conceptType);
  if (root == null) return null;
  vis.query.concept_type = root.type;

  // Match base query properties
  const newProps = mapValues(vis.query.properties ?? {}, (p) => matchPropertyOrChildren(p, root));
  if (Object.values(newProps).includes(null)) return null;
  vis.query.properties = newProps as Record<string, DerivedPropertyTerm>;

  // Match neighborhoods
  let conceptCursor: GraphConcept;
  vis.query.neighbors ||= {};
  for (const [neighId, path] of Object.entries(vis.query.neighbors)) {
    conceptCursor = root;
    const newPath: Neighborhood = [];
    for (const [linkDescr, conceptNode] of chunk(path, 2)) {
      newPath.push(linkDescr);
      const baseConceptType = isString(conceptNode)
        ? (conceptNode as ConceptKnowledgeRef)
        : conceptNode.concept_type;
      const partnerIds = getLinkPartners(conceptCursor.id, linkDescr as LinkDescriptor);
      const matchingConceptId = partnerIds.find((partnerId) =>
        isAncestorOf(getConcept(partnerId).type, baseConceptType)
      );
      if (matchingConceptId == null) return null;
      conceptCursor = getConcept(matchingConceptId);
      if (isString(conceptNode)) {
        newPath.push(conceptCursor.type);
      } else {
        const properties = mapValues(conceptNode.properties || {}, (prop) =>
          matchPropertyOrChildren(prop, conceptCursor)
        );
        if (Object.values(properties).includes(null)) return null;
        newPath.push({
          ...conceptNode,
          concept_type: conceptCursor.type,
          properties: properties as Record<string, DerivedPropertyTerm>,
        });
      }
    }
    vis.query.neighbors[neighId] = newPath;
  }

  // TODO: match direct prop references in other parts of query (filter, group, order)
  return vis;
}

function matchPropertyOrChildren(
  baseType: DerivedPropertyTerm,
  concept: GraphConcept
): DerivedPropertyTerm | null {
  const { isAncestorOf } = useKnowledge();
  if (isString(baseType))
    return concept.properties?.find((mp) => isAncestorOf(mp.type, baseType))?.type ?? null;
  switch (baseType.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.DateTrunc:
    case PropertyOpType.Ntile: {
      const innerType = matchPropertyOrChildren(baseType.property_type, concept);
      if (innerType == null) return null;
      return { ...baseType, property_type: innerType as PropertyKnowledgeRef };
    }
    case PropertyOpType.Add:
    case PropertyOpType.Subtract: {
      const termTypes = baseType.terms.map((t) => matchPropertyOrChildren(t, concept));
      if (termTypes.includes(null)) return null;
      return { ...baseType, terms: termTypes as DerivedPropertyTerm[] };
    }
    case PropertyOpType.Multiply: {
      const facTypes = baseType.factors.map((t) => matchPropertyOrChildren(t, concept));
      if (facTypes.includes(null)) return null;
      return { ...baseType, factors: facTypes as DerivedPropertyTerm[] };
    }
    case PropertyOpType.Divide: {
      const divisorType = matchPropertyOrChildren(baseType.divisor, concept);
      const dividendType = matchPropertyOrChildren(baseType.dividend, concept);
      if (divisorType == null || dividendType == null) return null;
      return { ...baseType, divisor: divisorType, dividend: dividendType };
    }
    case PropertyOpType.DateDiff: {
      const divisorType = matchPropertyOrChildren(baseType.start, concept);
      const dividendType = matchPropertyOrChildren(baseType.end, concept);
      if (divisorType == null || dividendType == null) return null;
      return { ...baseType, start: divisorType, end: dividendType };
    }
    case PropertyOpType.Count:
      return baseType;
  }
}

export function generateValue(
  generator: ValueGenerator,
  result: UseQueryResult,
  query: Partial<FetchNRequest>
): ValueWithFormattedValue | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const value = oversimplifyValues(result.aliases[generator.alias] ?? []);
      const propType = findPropertyDefByAlias(query, generator.alias);
      if (value == null || propType == null) return null;
      return formatValue(propType, value, generator.transformer);
    }
    case ValueGeneratorType.Title: {
      const concept = generator.tag != null ? result.tags[generator.tag]?.[0] : result.root;
      if (concept == null) return null;
      const title = readerConceptTitle(concept);
      if (title == null) return null;
      return noOpFormatValue(toValue(title));
    }
  }
}

export function generateValues(
  generators: Record<string, ValueGenerator>,
  result: UseQueryResult,
  query: Partial<FetchNRequest>
) {
  return mapValues(generators, (generator) => generateValue(generator, result, query));
}

// Gives the value type that a generator will return
export function generatorOutputType(
  generator: ValueGenerator,
  query: Partial<FetchNRequest>
): GraphValueType | null {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findPropertyDefByAlias(query, generator.alias);
      if (propDef == null) return null;
      const valueType = propertyValueType(propDef);
      if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return null;
      return valueType;
    }
    case ValueGeneratorType.Title:
      return GraphValueType.String;
  }
}

// Tries to come up with a reasonable label for a generator's output
export function generatorName(generator: ValueGenerator, query: Partial<FetchNRequest>): string {
  generator = normalizeGenerator(generator);
  switch (generator.type) {
    case ValueGeneratorType.Property: {
      const propDef = findPropertyDefByAlias(query, generator.alias);
      if (propDef == null) return "?";
      return propertyName(propDef, undefined); // TODO Probably include concept
    }
    case ValueGeneratorType.Title:
      return "Title"; // TODO Use concept name
  }
}

function normalizeGenerator(generator: ValueGenerator): NormalizedValueGenerator {
  if (isString(generator)) {
    return { type: ValueGeneratorType.Property, alias: generator };
  } else {
    return generator;
  }
}

export function oversimplifyValues(values: (GraphValue | GraphCompoundValue)[]) {
  if (values.length != 1) return null;
  if (!isValue(values[0])) return null; // Conveniently ignoring compound values for now
  return values[0] as GraphValue;
}
